import { type ColumnHelper, type DisplayColumnDef } from '@tanstack/react-table'

import { DragHandle } from 'components/ItemSelect/svg'

import classes from '../MatillionTable.module.scss'

const DragHandleHeader = () => null
const DragHandleCell = () => (
  <DragHandle
    data-testid="grid-editor-drag-handle"
    className={classes.DragHandle}
  />
)
export const dragHandleColumnId = 'draghandle'

export const useDragHandleColumn = <TCol,>(
  columnHelper: ColumnHelper<TCol>
): DisplayColumnDef<TCol, unknown> => {
  return columnHelper.display({
    id: dragHandleColumnId,
    header: DragHandleHeader,
    cell: DragHandleCell
  })
}
