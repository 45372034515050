import { useLocation, useNavigate } from 'react-router-dom'

import { type Files } from '@matillion/git-component-library'
import { PipelineType } from 'types/Pipeline'

import { newOrchestrationJob } from '__test-utils__/newOrchestrationJob'

import { useGetComponentMetadata } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { getExtension, JobType } from 'job-lib/types/JobType'

import { useCreatePipeline } from 'modules/FileBrowser/effects/useCreatePipeline/useCreatePipeline'

export const INITIAL_PIPELINE_IDS = [
  'GreenWave Pipelines/Create SHEET_NAMES.tran.yaml',
  'GreenWave Pipelines/GreenWave Technologies Demo.orch.yaml',
  'GreenWave Pipelines/Calculate Profit and Revenue.tran.yaml'
]

export const EXPERIMENT_LOCAL_STORAGE_KEY = 'firstPipeline'

export const useExperimentMyFirstPipeline = (files: Files) => {
  const { enableMyFirstPipelineExperiment } = useFlags()
  const { mutateAsync: createPipeline, isLoading } = useCreatePipeline()
  const { isLoading: isMetadataLoading } = useGetComponentMetadata('start')
  const firstPipelineCreated =
    localStorage.getItem(EXPERIMENT_LOCAL_STORAGE_KEY) === 'true'
  const fileName = `My first pipeline.${getExtension(JobType.Orchestration)}`
  const location = useLocation()
  const navigate = useNavigate()
  const { projectId, branchId, environmentId, agentId } = useProjectInfo()
  const { selectedJobs, selectedTasks } = useSelectedJobs()

  const isFirstPipeline =
    enableMyFirstPipelineExperiment &&
    Object.keys(files).length === 3 &&
    INITIAL_PIPELINE_IDS.every((id) =>
      Object.values(files).some(
        (pipeline) => pipeline.name === id && pipeline.status === 'UNMODIFIED'
      )
    ) &&
    !firstPipelineCreated

  if (isFirstPipeline && !isMetadataLoading) {
    // set the flag to prevent the experiment from running again
    // if the user deletes the new pipeline
    localStorage.setItem(EXPERIMENT_LOCAL_STORAGE_KEY, 'true')

    createPipeline({ type: PipelineType.Orchestration, name: fileName }).then(
      () => {
        navigate(
          {
            pathname: `/project/${projectId}/branch/${branchId}/job/${encodeURIComponent(
              fileName
            )}/component/${encodeURIComponent(
              newOrchestrationJob.components[1].id.toString()
            )}`,
            search: `environmentId=${environmentId}&agentId=${agentId}&closeRightPanel=true`
          },
          {
            state: {
              ...location.state,
              selectedJobs: [...selectedJobs, fileName],
              selectedTasks
            }
          }
        )
      }
    )
  }

  return { isLoading: isLoading || isMetadataLoading }
}
