const allWarehouses = ['databricks', 'snowflake', 'redshift']
const noHiddenParams = [
  { warehouse: 'databricks', hiddenParamIds: [] },
  { warehouse: 'snowflake', hiddenParamIds: [] },
  { warehouse: 'redshift', hiddenParamIds: [] }
]

/**
 * A key/value map containing the flag name and the fallback value for the flag. You should ensure the flag is created in
 * LaunchDarkly before adding it to this list. The fallback value will be used if the flag does not exist in LaunchDarkly, or in the case that
 * LaunchDarkly is unreachable
 *
 * You can set REACT_APP_ENVIRONMENT to dev/preprod/prod to test the flag combination from a different environment locally.
 * If REACT_APP_ENVIRONMENT is set to local, the fallback values in this file will be used as the flag values.
 */
export const flags: Record<string, unknown> = {
  'enable-agent-availability-modal': true,
  'enable-agent-stack-trace': true,
  'enable-component-toolbar': true,
  'enable-custom-connectors': true,
  'enable-experimental-ai-auto-docs': true,
  'enable-refine-ai-auto-docs': false,
  'enable-experimental-dpl': true,
  'enable-long-validation-error-expansion': true,
  'enable-monaco-autocomplete': false,
  'enable-move-editors-to-panel-batch1': true,
  'enable-import-export-pipelines': true,
  'enable-new-datatable': true,
  'enable-shared-pipelines': true,
  'enable-recently-accessed-pipelines': true,
  'enable-virtualised-table': true,
  'enable-wizard-ui-for-components': true,
  'enable-transformation-copilot': true,
  'enable-orchestration-copilot': true,
  'enable-view-full-conversion-report': true,
  'enable-add-custom-connector-cta': true,
  'enable-my-first-pipeline-experiment': true,
  'enable-sample-data-component-config-experiment': true,
  'enable-copilot-sql-expression-editor': true,
  'enable-run-from-component': true,
  'enable-run-only-component': true,
  'warehouses-add-partition': allWarehouses,
  'warehouses-openai-ai-prompt': allWarehouses,
  'warehouses-aws-ai-prompt': allWarehouses,
  'warehouses-azure-ai-prompt': allWarehouses,
  'warehouses-analyze-tables': allWarehouses,
  'warehouses-append-to-grid': allWarehouses,
  // API extract is not a component available in the UI. It is used as template for custom connectors.
  'warehouses-api-extract': [],
  'warehouses-assert-scalar-variables': allWarehouses,
  'warehouses-assert-table': allWarehouses,
  'warehouses-assert-table-v2': allWarehouses,
  'warehouses-azure-blob-storage-load': allWarehouses,
  'warehouses-azure-unload-sf': allWarehouses,
  'warehouses-azure-queue-storage-message': allWarehouses,
  'warehouses-bash-script': allWarehouses,
  'warehouses-bing-ads-query': allWarehouses,
  'warehouses-cloudwatch-publish': allWarehouses,
  'warehouses-commands-for-dbt-core': allWarehouses,
  'warehouses-convert-type-dl': allWarehouses,
  'warehouses-convert-type-rs': allWarehouses,
  'warehouses-cortex-completions': ['snowflake'],
  'warehouses-cortex-translate': ['snowflake'],
  'warehouses-cortex-extract-answer': ['snowflake'],
  'warehouses-cortex-sentiment': ['snowflake'],
  'warehouses-cortex-summarize': ['snowflake'],
  'warehouses-cortex-embed': ['snowflake'],
  'warehouses-vector-search': ['snowflake'],
  'warehouses-document-ai-predict': ['snowflake'],
  'warehouses-create-external-table-sf': allWarehouses,
  'warehouses-create-external-table-rs': ['redshift'],
  'warehouses-create-table': allWarehouses,
  'warehouses-custom-connectors': allWarehouses,
  'warehouses-data-transfer-object': allWarehouses,
  'warehouses-database-query': allWarehouses,
  'warehouses-databricks-ai-analyze-sentiment': ['databricks'],
  'warehouses-databricks-ai-classify': ['databricks'],
  'warehouses-databricks-ai-extract': ['databricks'],
  'warehouses-databricks-ai-fix-grammar': ['databricks'],
  'warehouses-databricks-ai-mask': ['databricks'],
  'warehouses-databricks-ai-query': ['databricks'],
  'warehouses-databricks-ai-similarity': ['databricks'],
  'warehouses-databricks-ai-summarize': ['databricks'],
  'warehouses-databricks-ai-translate': ['databricks'],
  'warehouses-databricks-vector-search': ['databricks'],
  'warehouses-delete-tables': allWarehouses,
  'warehouses-delete-tables-v2': allWarehouses,
  'warehouses-detect-changes': allWarehouses,
  'warehouses-distinct': allWarehouses,
  'warehouses-dynamics-365-query': allWarehouses,
  'warehouses-dynamodb-query': allWarehouses,
  'warehouses-email-query': allWarehouses,
  'warehouses-excel-query': allWarehouses,
  'warehouses-except': allWarehouses,
  'warehouses-extract-nested-data-dl': allWarehouses,
  'warehouses-extract-nested-data-rs': allWarehouses,
  'warehouses-facebook-ads-query': allWarehouses,
  'warehouses-facebook-query': allWarehouses,
  'warehouses-filter': allWarehouses,
  'warehouses-first-last': allWarehouses,
  'warehouses-fixed-flow': allWarehouses,
  'warehouses-flex-activecampaign': allWarehouses,
  'warehouses-flex-amplitude': allWarehouses,
  'warehouses-flex-box': allWarehouses,
  'warehouses-flex-braze': allWarehouses,
  'warehouses-flex-brevo': allWarehouses,
  'warehouses-flex-chargebee': allWarehouses,
  'warehouses-flex-circleci': allWarehouses,
  'warehouses-flex-concord': allWarehouses,
  'warehouses-flex-confluence': allWarehouses,
  'warehouses-flex-conservice': allWarehouses,
  'warehouses-flex-datadog': allWarehouses,
  'warehouses-flex-delighted': allWarehouses,
  'warehouses-flex-dropbox': allWarehouses,
  'warehouses-flex-eventbrite': allWarehouses,
  'warehouses-flex-freshdesk': allWarehouses,
  'warehouses-flex-gong': allWarehouses,
  'warehouses-flex-instagram': allWarehouses,
  'warehouses-flex-klaviyo': allWarehouses,
  'warehouses-flex-launchdarkly': allWarehouses,
  'warehouses-flex-pagerduty': allWarehouses,
  'warehouses-flex-pardot': allWarehouses,
  'warehouses-flex-paypal': allWarehouses,
  'warehouses-flex-pendo': allWarehouses,
  'warehouses-flex-pipedrive': allWarehouses,
  'warehouses-flex-productboard': allWarehouses,
  'warehouses-flex-recurly': allWarehouses,
  'warehouses-flex-slack': allWarehouses,
  'warehouses-flex-smartsheet': allWarehouses,
  'warehouses-flex-snapchat': allWarehouses,
  'warehouses-flex-snyk': allWarehouses,
  'warehouses-flex-square': allWarehouses,
  'warehouses-flex-tiktok': allWarehouses,
  'warehouses-flex-toggl': allWarehouses,
  'warehouses-flex-twilio': allWarehouses,
  'warehouses-flex-zendeskchat': allWarehouses,
  'warehouses-flex-zendesksupport': allWarehouses,
  'warehouses-flex-zendesk-talk': allWarehouses,
  'warehouses-flex-shopify': allWarehouses,
  'warehouses-flex-github': allWarehouses,
  'warehouses-flex-sendgrid': allWarehouses,
  'warehouses-flex-yelp': allWarehouses,
  'warehouses-flex-dixa': allWarehouses,
  'warehouses-flex-ongoing-wms': allWarehouses,
  'warehouses-flex-stripe': allWarehouses,
  'warehouses-flex-mandrill': allWarehouses,
  'warehouses-flex-intercom': allWarehouses,
  'warehouses-flex-mixpanel': allWarehouses,
  'warehouses-flex-ticketmaster': allWarehouses,
  'warehouses-flex-new-relic': allWarehouses,
  'warehouses-flex-sap-successfactors': allWarehouses,
  'warehouses-flex-asana': allWarehouses,
  'warehouses-flex-salesloft': allWarehouses,
  'warehouses-flex-zendesk-ticketing': allWarehouses,
  'warehouses-flex-bugcrowd': allWarehouses,
  'warehouses-gcs-load': allWarehouses,
  'warehouses-gcs-unload': allWarehouses,
  'warehouses-generate-sequence': allWarehouses,
  'warehouses-google-ads-qry': allWarehouses,
  'warehouses-google-ads-query': allWarehouses,
  'warehouses-google-analytics-query': allWarehouses,
  'warehouses-google-bigquery': allWarehouses,
  'warehouses-google-sheets-query': allWarehouses,
  'warehouses-hubspot-query': allWarehouses,
  'warehouses-intersect': allWarehouses,
  'warehouses-jira-query': allWarehouses,
  'warehouses-join': allWarehouses,
  'warehouses-ldap-query': allWarehouses,
  'warehouses-lead-lag': allWarehouses,
  'warehouses-llm-prompt': allWarehouses,
  'warehouses-map-values': allWarehouses,
  'warehouses-marketo-query': allWarehouses,
  'warehouses-modular-anaplan-input-v1': allWarehouses,
  'warehouses-modular-api-extract-input-v1': allWarehouses,
  'warehouses-modular-azure-sql-input-v0': allWarehouses,
  'warehouses-modular-databricks-input-v0': allWarehouses,
  'warehouses-modular-google-sheets-input-v0': allWarehouses,
  'warehouses-modular-kafka-input-v1': allWarehouses,
  'warehouses-modular-load-salesforce-input-v0': allWarehouses,
  'warehouses-modular-google-analytics-input-v0': allWarehouses,
  'warehouses-modular-jira-input-v0': allWarehouses,
  'warehouses-modular-odata-input-v0': allWarehouses,
  'warehouses-modular-mailchimp-input-v0': allWarehouses,
  'warehouses-modular-salesforce-input-v0': allWarehouses,
  'warehouses-modular-spotify-input-v1': allWarehouses,
  'warehouses-modular-stub-input-connector-v0': allWarehouses,
  'warehouses-modular-workday-custom-reports-input-v1': allWarehouses,
  'warehouses-modular-workday-input-v1': allWarehouses,
  'warehouses-modular-textract-input-v1': allWarehouses,
  'warehouses-modular-aws-transcribe-input-v1': allWarehouses,
  'warehouses-modular-azure-document-intelligence-input-v1': allWarehouses,
  'warehouses-mongodb-query': allWarehouses,
  'warehouses-modular-redshift-input-v0': allWarehouses,
  'warehouses-multi-table-input': allWarehouses,
  'warehouses-create-table-v2': allWarehouses,
  'warehouses-netsuite-query': allWarehouses,
  'warehouses-odata-query': allWarehouses,
  'warehouses-optimise': allWarehouses,
  'warehouses-pivot': allWarehouses,
  'warehouses-print-variables': allWarehouses,
  'warehouses-prompt-component': allWarehouses,
  'warehouses-pubsub': allWarehouses,
  'warehouses-python-pushdown': allWarehouses,
  'warehouses-python-script': allWarehouses,
  'warehouses-rank': allWarehouses,
  'warehouses-rds-query': allWarehouses,
  'warehouses-refresh-materialized-view': allWarehouses,
  'warehouses-refresh-table-v2': allWarehouses,
  'warehouses-remove-from-grid': allWarehouses,
  'warehouses-rename': allWarehouses,
  'warehouses-rewrite-table-dl': allWarehouses,
  'warehouses-rewrite-table-rs': allWarehouses,
  'warehouses-run-notebook': allWarehouses,
  'warehouses-run-shared-pipeline': allWarehouses,
  'warehouses-s3-load-rs': allWarehouses,
  'warehouses-s3-load-v2': allWarehouses,
  'warehouses-s3-unload-rs': allWarehouses,
  'warehouses-salesforce-marketing-cloud-query': allWarehouses,
  'warehouses-salesforce-query': allWarehouses,
  'warehouses-schema-copy': allWarehouses,
  'warehouses-send-email': allWarehouses,
  'warehouses-sharepoint-query': allWarehouses,
  'warehouses-sns-message': allWarehouses,
  'warehouses-split-field': allWarehouses,
  'warehouses-sql': allWarehouses,
  'warehouses-sql-script': allWarehouses,
  'warehouses-sql-executor': allWarehouses,
  'warehouses-sqs-message': allWarehouses,
  'warehouses-start': allWarehouses,
  'warehouses-start-compute': allWarehouses,
  'warehouses-stripe-query': allWarehouses,
  'warehouses-table-delete-rows': allWarehouses,
  'warehouses-table-input': allWarehouses,
  'warehouses-table-iterator': allWarehouses,
  'warehouses-table-output': allWarehouses,
  'warehouses-table-update': allWarehouses,
  'warehouses-transpose-rows': allWarehouses,
  'warehouses-transpose-columns': allWarehouses,
  'warehouses-unite': allWarehouses,
  'warehouses-unpivot': allWarehouses,
  'warehouses-update-scalar': allWarehouses,
  'warehouses-vacuum-tables': allWarehouses,
  'warehouses-pinecone-vector-upsert': allWarehouses,
  'warehouses-pinecone-vector-query': allWarehouses,
  'warehouses-postgres-vector-upsert': allWarehouses,
  'warehouses-snowflake-vector-upsert': ['snowflake'],
  'warehouses-vector-upsert': allWarehouses,
  'warehouses-webhook-post': allWarehouses,
  'warehouses-window-calculation': allWarehouses,
  'warehouses-spcs-ai-prompt': allWarehouses,
  'warehouses-truncate-table-v2': allWarehouses,
  'warehouses-table-metadata-to-grid': allWarehouses,
  'warehouses-jdbc-table-metadata-to-grid': allWarehouses,
  'warehouses-bash-pushdown': allWarehouses,
  'operations-hidden-params': noHiddenParams,
  'enable-high-code': false,
  'warehouses-chunk-text-sf': ['snowflake'],
  'enable-hiding-superseded-components': false,
  'enable-connect-my-environment-cta': false,
  'enable-relative-start-position-and-zoom': false,
  'rollout-enable-working-copy-provider': false,
  'enable-command-palette': false,
  'enable-migration-tool-mappings': false
}
