import { useCallback, useState, type FC, type KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Icon,
  Toaster,
  Tooltip,
  TreeItem,
  Typography
} from '@matillion/component-library'
import {
  FileClassification,
  FileIcon,
  FileType
} from '@matillion/git-component-library'
import classNames from 'classnames'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'
import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'

import { PopOverMenu } from 'components/PopOverMenu'

import { useFlags } from 'hooks/useFlags'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { JobType } from 'job-lib/types/JobType'

import { SharedPipelineIcon } from 'modules/FileBrowser/icons/SharedPipelineIcon'

import ContextMenuButton from '../ContextMenuButton/ContextMenuButton'
import FileItemContextMenu from '../FileBrowserTree/FileItemContextMenu/FileItemContextMenu'
import FileItemDragSource from '../FileItemDragSource/FileItemDragSource'
import { OptionalDrag, useJobDragProps } from '../OptionalDrag'
import classes from './FileItem.module.scss'

interface PipelineItemProps {
  file: JobSummary
  className?: string
  isLeaf: boolean
  path?: string
}

const FileItem: FC<PipelineItemProps> = ({ file, isLeaf, className, path }) => {
  const { jobId } = file
  const { navigateToJob } = useSelectedJobs()
  const dragProps = useJobDragProps(file)
  const [isDragging, setIsDragging] = useState(false)
  const { checkPipelineShared } = useSharePipeline()
  const { enableSharedPipelines, enableHighCode } = useFlags()

  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation('translation', { keyPrefix: 'sideBar.jobPanel' })

  const handleDragStart = () => {
    setIsDragging(true)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      openFile()
    }
  }

  const fileClassification = file.class ?? FileClassification.CLASS_1

  const openFile = useCallback(() => {
    if (
      fileClassification <= FileClassification.CLASS_2 &&
      file.fileType !== FileType.JSON
    ) {
      navigateToJob(jobId)
    } else {
      makeToast({
        type: 'error',
        title: t('cannot-be-opened.title'),
        message: t('cannot-be-opened.message')
      })
    }
  }, [file.fileType, fileClassification, jobId, makeToast, navigateToJob, t])

  const fileName = file.displayName ?? file.name

  return (
    <FileItemDragSource
      path={path}
      pipeline={file}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <div
        className={classNames(classes.TreeItemWrapper, {
          [classes.Disabled]: isDragging
        })}
      >
        <Icon.DragHandle className={classes.DragHandle} />

        <TreeItem
          nodeId={jobId}
          label={fileName}
          leaf={
            <PopOverMenu
              key={jobId}
              content={<FileItemContextMenu file={file} path={path} />}
            >
              {({ onClick }) => {
                return (
                  <div
                    tabIndex={0}
                    className={classNames(className, classes.ItemContainer, {
                      [classes.Leaf]: isLeaf
                    })}
                    onKeyDown={handleKeyDown}
                    onClick={openFile}
                    data-testid={`job-list-item-${jobId}`}
                  >
                    <OptionalDrag
                      dragProps={dragProps}
                      className={classes.Item}
                    >
                      <div className={classes.Item__Name}>
                        <span className={classes.Icon}>
                          {enableHighCode ? (
                            <FileIcon type={file.fileType} />
                          ) : (
                            <FileIcon
                              type={
                                file.type === JobType.Orchestration
                                  ? FileType.ORCHESTRATION_PIPELINE
                                  : FileType.TRANSFORMATION_PIPELINE
                              }
                            />
                          )}
                        </span>

                        <Tooltip
                          onlyShowOnOverflow
                          content={isDragging ? '' : fileName}
                          className={classes.ItemContainer__Tooltip}
                        >
                          <div
                            className={classNames(classes.TitleText, {
                              [classes.UnInteractableFile]:
                                fileClassification >= FileClassification.CLASS_3
                            })}
                          >
                            <Typography
                              format="bcs"
                              as="span"
                              className={classNames(classes.FileName)}
                              data-testid="job-name"
                            >
                              {fileName}
                            </Typography>
                          </div>
                        </Tooltip>
                      </div>

                      <div className={classes.Item__Context}>
                        {enableSharedPipelines &&
                          checkPipelineShared(file.jobId) && (
                            <SharedPipelineIcon
                              className={classes.Item__SharedPipelineIcon}
                              testId={`shared-pipeline-${file.jobId}`}
                            />
                          )}

                        <ContextMenuButton
                          id={jobId}
                          key={jobId}
                          onClick={onClick}
                        />
                      </div>
                    </OptionalDrag>
                  </div>
                )
              }}
            </PopOverMenu>
          }
        />
      </div>
    </FileItemDragSource>
  )
}

export default FileItem
