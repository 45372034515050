import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { isCustomOrFlexConnector } from 'job-lib/cisIds/idType'
import {
  componentNameParameter,
  unknownComponentIds
} from 'job-lib/cisIds/knownComponentParameters'

import classes from './ComponentParameters.module.scss'
import { ComponentConfigurationPanel } from './components/ComponentConfigurationPanel/ComponentConfigurationPanel'
import { wizardConfigs } from './components/ComponentConfigurationPanel/wizardConfigs'
import { ComponentParameterItem } from './components/ComponentParameterItem/ComponentParameterItem'
import { type ComponentParametersWizardProps } from './types'

export const ComponentParameterWizardContainer: FC<
  ComponentParametersWizardProps
> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit,
  onBlur
}) => {
  const { t } = useTranslation()
  const componentId = isCustomOrFlexConnector(componentSummaryId)
    ? 'modular-api-extract-input-v1'
    : componentSummaryId

  const wizardConfig = wizardConfigs[componentId]

  return wizardConfig ? (
    <ComponentConfigurationPanel
      onEdit={onEdit}
      onBlur={onBlur}
      componentMetadata={componentMetadata}
      componentSummaryId={componentSummaryId}
      componentInstance={componentInstance}
      wizardConfig={wizardConfig}
    />
  ) : (
    <div
      id="wizard-component-parameters"
      data-testid="component-parameters"
      className={classes.ComponentParameters__Container}
    >
      {unknownComponentIds.includes(componentId) && (
        <EmptyPanel> {t('componentProperties.noPreview')} </EmptyPanel>
      )}
      {componentMetadata.parameters
        .filter((parameter) => parameter.dplID !== componentNameParameter)
        .map((parameter) => (
          <ComponentParameterItem
            key={`parameter-${parameter.dplID}`}
            parameter={parameter}
            componentSummaryId={componentSummaryId}
            componentInstance={componentInstance}
            componentMetadata={componentMetadata}
            onEdit={onEdit}
            onBlur={onBlur}
            scrollableContainerSelector="#wizard-component-parameters"
          />
        ))}
    </div>
  )
}
