import { cloneDeep, toUpper } from 'lodash'

import type {
  ComponentMetadata,
  ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { parseLookupDependencyKey } from 'hooks/useParameterOptions/parseLookupDependencyKey'

const concat = (parentKey: string | undefined, childKey: string) =>
  parentKey ? `${parentKey}.${childKey}` : childKey

function filterSupportedAndPutRemovedInSet(
  componentParameters: ComponentParameter[],
  projectWarehouse: string,
  unsupportedParameters: Set<string>,
  parentDplId?: string
) {
  return componentParameters.filter((parameter) => {
    // Get the state of the parent parameter (if present).
    let isSupported = isParentSupported(parentDplId, unsupportedParameters)

    const childDplId = concat(parentDplId, parameter.dplID)

    // If the parent parameter is supported, then lets check the current child parameter and its warehouse overrides.
    // If the parent parameter is not supported, then the child parameter is also not supported.
    if (isSupported && parameter.warehouses) {
      const paramOverride = parameter.warehouses[toUpper(projectWarehouse)]
      if (paramOverride?.metlSlot) {
        parameter.metlSlot = paramOverride?.metlSlot
      }
      isSupported = !!paramOverride
    }

    if (!isSupported) {
      // We have found an unsupported parameter, lets add it to the set.
      unsupportedParameters.add(childDplId)
    }

    if (parameter.childProperties) {
      parameter.childProperties = filterSupportedAndPutRemovedInSet(
        parameter.childProperties,
        projectWarehouse,
        unsupportedParameters,
        childDplId
      )
    }

    return isSupported
  })
}

function isParentSupported(
  parentDplId: string | undefined,
  unsupportedParameters: Set<string>
) {
  return !parentDplId || !unsupportedParameters.has(parentDplId)
}

// parameter should be removed from visibleWhen and lookupDependencies if it refers to some unsupported parameter.
function removeUnsupportedParametersProperties(
  parameters: ComponentParameter[],
  unsupportedParameters: Set<string>,
  parentDplId?: string
) {
  parameters.forEach((parameter) => {
    const childDplId = concat(parentDplId, parameter.dplID)

    // recursively remove unsupported parameters from childProperties.
    if (parameter.childProperties) {
      removeUnsupportedParametersProperties(
        parameter.childProperties,
        unsupportedParameters,
        childDplId
      )
    }

    // remove unsupported parameters from visibleWhen.
    if (parameter.visibleWhen) {
      parameter.visibleWhen = parameter.visibleWhen.filter(({ param }) => {
        return param === null || !unsupportedParameters.has(param)
      })
    }

    // remove unsupported parameters from staticOptions lookupDependencies.
    if (parameter.staticOptions) {
      parameter.staticOptions
        .filter((ec) => ec.lookupDependencies)
        .forEach((ec) => {
          ec.lookupDependencies = getStaticOptionsLookupDependencies(
            ec,
            unsupportedParameters,
            parameters
          )
        })
    }

    // remove unsupported parameters from lookupDependencies.
    if (parameter.lookupDependencies) {
      parameter.lookupDependencies = parameter.lookupDependencies.filter((ld) =>
        isSupportedLookup(ld, unsupportedParameters, parameters)
      )
    }
  })
}

function getStaticOptionsLookupDependencies(
  editorColumn: EditorColumn,
  unsupportedParameters: Set<string>,
  parameters: ComponentParameter[]
) {
  // @ts-expect-error lookupDependencies never null
  return editorColumn.lookupDependencies.filter((ld) =>
    isSupportedLookup(ld, unsupportedParameters, parameters)
  )
}

function isSupportedLookup(
  dependency: string,
  unsupportedParameters: Set<string>,
  parameters: ComponentParameter[]
) {
  const key = parseLookupDependencyKey('param', dependency)
  if (!key) {
    // The lookup dependency key is not in the expected format, then we assume it is supported.
    // This covers the cases where the lookup dependency is not another parameter (transform.sql, etc).
    return true
  }

  // if the parameter is a top-level parameter, then we check the parameter is present and therefore supported.
  if (key.parameterPath.length === 1) {
    return hasRelativeDep(key.parameterPath[0], parameters)
  }

  // if the parameter is a child parameter, then we check full path is part of the known supported parameters.
  const fullPath = key?.parameterPath.join('.')
  return !unsupportedParameters.has(fullPath)
}

function hasRelativeDep(param: string, parameters: ComponentParameter[]) {
  return parameters.find((p) => p.dplID === param) !== undefined
}

export const removeUnsupportedParametersAndReplaceSlot = (
  projectWarehouse: string,
  metadata: ComponentMetadata
) => {
  const output = cloneDeep(metadata)

  const unSupportedParameters = new Set<string>()
  output.parameters = filterSupportedAndPutRemovedInSet(
    output.parameters,
    projectWarehouse,
    unSupportedParameters
  )

  removeUnsupportedParametersProperties(
    output.parameters,
    unSupportedParameters
  )

  return output
}
