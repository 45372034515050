import { useCallback, useMemo } from 'react'

import { FileType } from '@matillion/git-component-library'
import { escapeRegExp } from 'lodash'

import { useAgentHost } from 'api/hooks/useAgentHost/useAgentHost'
import {
  useConnectorComponentSummaries,
  useStandardComponentSummaries,
  type ComponentContext,
  type ComponentSummary
} from 'api/hooks/useGetComponentSummaries'
import { type ProjectType } from 'api/hooks/useGetProject/types'
import useGetProject from 'api/hooks/useGetProject/useGetProject'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'
import { useFlags } from 'hooks/useFlags'

import { isCustomConnector } from 'job-lib/cisIds/idType'

import { getSupersededFilter } from 'modules/ComponentParameters/utils/isComponentSuperseded'

import { getPseudoComponents } from '../../api/hooks/useGetPseudoComponents/useGetPseudoComponents'
import {
  getEnabledComponents,
  isPreviewComponent,
  useComponentEnabledWarehouses
} from '../useComponentEnabledWarehouses'
import { useComponentInfo } from '../useComponentInfo/useComponentInfo'

export interface ExtendedProps extends ComponentSummary {
  isAvailableForAgent: boolean
  isPreview: boolean
  displayName: string
  tags: string[]
  synonyms: string[]
  icon: string
  description?: string
}

export type FlatComponentList = ExtendedProps[]

const searchComponentList = (
  searchTerm: string,
  components: FlatComponentList
): FlatComponentList => {
  const sortedComponents = [...components].sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  )
  if (searchTerm === '') {
    return sortedComponents
  }

  const searchTermRegex = new RegExp('\\b' + escapeRegExp(searchTerm), 'i')

  const { nameMatches, tagMatches, synonymMatches } = sortedComponents.reduce(
    (matchBag, component) => {
      if (searchTermRegex.test(component.displayName)) {
        matchBag.nameMatches.push(component)
        return matchBag
      }

      if (component.tags.some((tag) => searchTermRegex.test(tag))) {
        matchBag.tagMatches.push(component)
        return matchBag
      }

      if (component.synonyms.some((synonym) => searchTermRegex.test(synonym))) {
        matchBag.tagMatches.push(component)
        return matchBag
      }

      return matchBag
    },
    {
      nameMatches: [] as FlatComponentList,
      tagMatches: [] as FlatComponentList,
      synonymMatches: [] as FlatComponentList
    }
  )

  return [...nameMatches, ...tagMatches, ...synonymMatches]
}

/* any components that are incompatible with the current agentHost are omitted
 * with the exception of this list of component overrides.
 * Components in this list will display on incompatible agents with the `Coming soon` label
 */
const agentHostComponentOverrides = ['commands-for-dbt-core']

const getComponentContext = (type?: FileType): ComponentContext | undefined => {
  switch (type) {
    case FileType.ORCHESTRATION_PIPELINE:
      return 'ORCHESTRATION'
    case FileType.TRANSFORMATION_PIPELINE:
      return 'TRANSFORMATION'
    default:
      return undefined
  }
}

const useAvailableComponents = () => {
  const { getDisplayName, getTags, getIcon, getSynonyms, getDescription } =
    useComponentInfo()
  const pseudoComponentMetadata = useMemo(() => getPseudoComponents(), [])

  const {
    agentHost,
    isInitialLoading,
    isError: isAgentHostError
  } = useAgentHost()

  const hubFlags = useFlags()
  const { data: project } = useGetProject()
  const componentEnabledWarehouses = useComponentEnabledWarehouses()
  const enabledComponents = getEnabledComponents(
    componentEnabledWarehouses,
    project?.warehouse as ProjectType
  )
  const { pipelineSummary } = useActivePipelineSummary()
  const componentContext = getComponentContext(pipelineSummary?.type)

  const {
    data: standardComponentsData,
    isLoading,
    isError
  } = useStandardComponentSummaries(componentContext)
  const { data: connectorComponentsData } =
    useConnectorComponentSummaries(componentContext)

  const supersededFilter = getSupersededFilter({
    flagEnabled: hubFlags.enableHidingSupersededComponents,
    componentEnabledWarehouses,
    projectType: project?.warehouse as ProjectType
  })

  const componentMapper = useCallback(
    (current: ComponentSummary) => ({
      ...current,
      displayName: getDisplayName(current.componentId),
      icon: getIcon(current.componentId),
      tags: getTags(current.componentId),
      synonyms: getSynonyms(current.componentId),
      isAvailableForAgent:
        agentHost !== null && current.agentHosts.includes(agentHost),
      description: getDescription(current.componentId),
      isPreview: isPreviewComponent(
        hubFlags,
        current.componentId,
        project?.warehouse as ProjectType
      )
    }),
    [
      getDisplayName,
      getIcon,
      getTags,
      getSynonyms,
      agentHost,
      getDescription,
      hubFlags,
      project?.warehouse
    ]
  )

  const defaultComponents: ExtendedProps[] = useMemo(() => {
    if (!standardComponentsData || !agentHost) {
      return []
    }

    return standardComponentsData
      .filter(
        ({ componentId, agentHosts }) =>
          enabledComponents.includes(componentId) &&
          (agentHosts.includes(agentHost) ||
            agentHostComponentOverrides.includes(componentId))
      )
      .filter(supersededFilter)
      .map<ExtendedProps>(componentMapper)
  }, [
    standardComponentsData,
    agentHost,
    enabledComponents,
    supersededFilter,
    componentMapper
  ])

  const connectorComponents: ExtendedProps[] = useMemo(() => {
    if (!connectorComponentsData || !agentHost) {
      return []
    }

    return connectorComponentsData
      .filter(
        ({ componentId }) =>
          (enabledComponents.includes('custom-connectors') &&
            isCustomConnector(componentId)) ||
          enabledComponents.includes(componentId)
      )
      .filter(supersededFilter)
      .map<ExtendedProps>(componentMapper)
  }, [
    connectorComponentsData,
    agentHost,
    enabledComponents,
    supersededFilter,
    componentMapper
  ])

  // For each pseudo component with a matching real component, clone the real component and replace the name/id
  // with the pseudo component name/id
  const pseudoComponents: ExtendedProps[] = useMemo(() => {
    return pseudoComponentMetadata
      .flatMap((metadata) =>
        metadata.pseudoComponents.map((pseudoComponent) => ({
          componentId: metadata.componentId,
          originalComponent: [
            ...defaultComponents,
            ...connectorComponents
          ].find((c) => c.componentId === metadata.componentId),
          pseudoComponent
        }))
      )
      .filter((c) => c.originalComponent)
      .map((c) => ({
        ...(c.originalComponent as ExtendedProps),
        displayName: c.pseudoComponent.name,
        icon: c.pseudoComponent.icon
      }))
  }, [pseudoComponentMetadata, defaultComponents, connectorComponents])

  // Check if there are any pseudo components where the original component is hidden
  const hiddenComponents = useMemo(
    () =>
      pseudoComponentMetadata
        .filter((p) => p.originalComponentHidden)
        .map((p) => p.componentId),
    [pseudoComponentMetadata]
  )

  const components = useMemo(() => {
    return [...defaultComponents, ...connectorComponents]
      .filter((component) => !hiddenComponents.includes(component.componentId))
      .concat(pseudoComponents)
  }, [
    connectorComponents,
    defaultComponents,
    hiddenComponents,
    pseudoComponents
  ])

  if (!pipelineSummary) {
    return {
      isLoading: false,
      isError: false,
      components: []
    }
  }

  if (
    !standardComponentsData ||
    isError ||
    isAgentHostError ||
    isLoading ||
    isInitialLoading ||
    !agentHost
  ) {
    return {
      isLoading: isLoading || isInitialLoading,
      isError: isError || isAgentHostError,
      components: []
    }
  }

  return {
    isLoading: false,
    isError: false,
    components
  }
}

export {
  searchComponentList,
  useAvailableComponents,
  agentHostComponentOverrides
}
