import { type WizardStep } from '../../types'

const defaultParametersList = [
  'outputId',
  'databricks-output-connector-v0',
  'redshift-output-connector-v0',
  'snowflake-output-connector-v0',
  'storage-only-output-v0'
]

export function createModularConnectorDestinationStep(
  previousStepId: string | null,
  nextStepId: string | null,
  parametersList = defaultParametersList
): WizardStep {
  return {
    stepId: 'destination',
    displayName: 'Destination',
    previousStepId,
    nextStepId,
    parameters: parametersList.map((dplId) => ({ dplId }))
  }
}
