import { useMemo } from 'react'

import { useFlags as useHubFlags } from '@matillion/hub-client'

export interface EtlFlags {
  [key: string]: boolean | string[]
  enableAgentStackTrace: boolean
  enableAgentAvailabilityModal: boolean
  /** Enables both custom and flex connectors */
  enableCustomConnectors: boolean
  enableExperimentalAiAutoDocs: boolean
  enableRefineAiAutoDocs: boolean
  enableImportExportPipelines: boolean
  enableSharedPipelines: boolean
  enableRecentlyAccessedPipelines: boolean
  enableTransformationCopilot: boolean
  enableOrchestrationCopilot: boolean
  enableViewFullConversionReportButton: boolean
  enableAddCustomConnectorCta: boolean
  enableVirtualisedTable: boolean
  enableMyFirstPipelineExperiment: boolean
  rolloutEnableWorkingCopyProvider: boolean
  enableHighCode: boolean
  enableCopilotSQLExpressionEditor: boolean
  enableNewDataTable: boolean
  enableMoveEditorsToPanelBatch1: boolean
  enableComponentToolbar: boolean
  enableRunFromComponent: boolean
  enableLongValidationErrorExpansion: boolean
  enableHidingSupersededComponents: boolean
  enableRunOnlyComponent: boolean
  enableRelativeStartPositionAndZoom: boolean
  enableMonacoAutocomplete: boolean
  enableSampleDataComponentConfigExperiment: boolean
}

export const useFlags = (): EtlFlags => {
  const hubFlags = useHubFlags()

  return useMemo(
    () => ({
      enableAgentStackTrace: Boolean(hubFlags['enable-agent-stack-trace']),
      enableAgentAvailabilityModal: Boolean(
        hubFlags['enable-agent-availability-modal']
      ),
      /** Enables both custom and flex connectors */
      enableCustomConnectors: Boolean(hubFlags['enable-custom-connectors']),
      enableExperimentalAiAutoDocs: Boolean(
        hubFlags['enable-experimental-ai-auto-docs']
      ),
      enableRefineAiAutoDocs: Boolean(hubFlags['enable-refine-ai-auto-docs']),
      enableImportExportPipelines: Boolean(
        hubFlags['enable-import-export-pipelines']
      ),
      enableSharedPipelines: Boolean(hubFlags['enable-shared-pipelines']),
      enableRecentlyAccessedPipelines: Boolean(
        hubFlags['enable-recently-accessed-pipelines']
      ),
      enableTransformationCopilot: Boolean(
        hubFlags['enable-transformation-copilot']
      ),
      enableOrchestrationCopilot: Boolean(
        hubFlags['enable-orchestration-copilot']
      ),
      enableViewFullConversionReportButton: Boolean(
        hubFlags['enable-view-full-conversion-report']
      ),
      enableAddCustomConnectorCta: Boolean(
        hubFlags['enable-add-custom-connector-cta']
      ),
      enableVirtualisedTable: Boolean(hubFlags['enable-virtualised-table']),
      rolloutEnableWorkingCopyProvider: Boolean(
        hubFlags['rollout-enable-working-copy-provider']
      ),
      enableMyFirstPipelineExperiment: Boolean(
        hubFlags['enable-my-first-pipeline-experiment']
      ),
      enableSampleDataComponentConfigExperiment: Boolean(
        hubFlags['enable-sample-data-component-config-experiment']
      ),
      enableHighCode: Boolean(hubFlags['enable-high-code']),
      enableCopilotSQLExpressionEditor: Boolean(
        hubFlags['enable-copilot-sql-expression-editor']
      ),
      enableNewDataTable: Boolean(hubFlags['enable-new-datatable']),
      enableMoveEditorsToPanelBatch1: Boolean(
        hubFlags['enable-move-editors-to-panel-batch1']
      ),
      enableComponentToolbar: Boolean(hubFlags['enable-component-toolbar']),
      enableRunFromComponent: Boolean(hubFlags['enable-run-from-component']),
      enableRunOnlyComponent: Boolean(hubFlags['enable-run-only-component']),
      enableConnectMyEnvironmentCta: Boolean(
        hubFlags['enable-connect-my-environment-cta']
      ),
      enableHidingSupersededComponents: Boolean(
        hubFlags['enable-hiding-superseded-components']
      ),
      enableLongValidationErrorExpansion: Boolean(
        hubFlags['enable-long-validation-error-expansion']
      ),
      enableRelativeStartPositionAndZoom: Boolean(
        hubFlags['enable-relative-start-position-and-zoom']
      ),
      enableMonacoAutocomplete: Boolean(hubFlags['enable-monaco-autocomplete']),
      enableCommandPalette: Boolean(hubFlags['enable-command-palette']),
      enableMigrationToolMappings: Boolean(
        hubFlags['enable-migration-tool-mappings']
      ),
      ...hubFlags
    }),
    [hubFlags]
  )
}
