import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { type Classification } from '../types/Components'

export const isFlexConnector = (componentSummaryId: ComponentSummaryId) =>
  componentSummaryId.startsWith('flex-')
export const isCustomConnector = (componentSummaryId: ComponentSummaryId) =>
  componentSummaryId.startsWith('custom-')
export const isModularConnector = (componentSummaryId: ComponentSummaryId) =>
  componentSummaryId.startsWith('modular-')

export const isModularFlexOrCustom = (componentSummaryId: ComponentSummaryId) =>
  isModularConnector(componentSummaryId) ||
  isCustomConnector(componentSummaryId) ||
  isFlexConnector(componentSummaryId)

export const isDplParamsRequired = (classification: Classification) =>
  classification === 'NATIVE' || classification === 'MODULAR'

export const isCustomOrFlexConnector = (componentId: ComponentSummaryId) =>
  isCustomConnector(componentId) || isFlexConnector(componentId)
