import { Draggable } from '@hello-pangea/dnd'
import { Typography } from '@matillion/component-library'
import { flexRender, type Row } from '@tanstack/react-table'
import classNames from 'classnames'

import { checkboxColumnId } from './Columns/Checkbox'
import { dragHandleColumnId } from './Columns/DragHandle'
import classes from './MatillionTable.module.scss'

interface MatillionTableRowProps<TData> {
  currentlyDraggingId: string | null
  row: Row<TData>
  selectedRowCount: number
}

export const MatillionTableRow = <TData,>({
  currentlyDraggingId,
  row,
  selectedRowCount
}: MatillionTableRowProps<TData>) => {
  const isGhosting =
    row.getIsSelected() &&
    Boolean(currentlyDraggingId) &&
    currentlyDraggingId !== row.id

  return (
    <Draggable key={row.id} draggableId={row.id} index={row.index}>
      {(draggableProvided, snapshot) => {
        return (
          <tr
            className={classNames({
              [classes['tr-dragging']]: snapshot.isDragging,
              [classes['tr-ghosting']]: isGhosting
            })}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
          >
            {row.getVisibleCells().map((cell) => {
              const isDragHandle = cell.column.id === dragHandleColumnId
              const props = isDragHandle
                ? draggableProvided.dragHandleProps
                : {}
              return (
                <td
                  className={classNames({
                    [classes['td-dragging']]: snapshot.isDragging,
                    [classes['td-checkbox']]:
                      cell.column.id === checkboxColumnId,
                    [classes['td-draghandle']]: isDragHandle
                  })}
                  {...props}
                  onMouseDown={(e) => {
                    if (isDragHandle) {
                      e.currentTarget.focus()
                    }
                  }}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              )
            })}
            {snapshot.isDragging && selectedRowCount > 1 && (
              <td className={classes['row-count']}>
                <Typography>{selectedRowCount}</Typography>
              </td>
            )}
          </tr>
        )
      }}
    </Draggable>
  )
}
