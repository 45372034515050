import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { type JobSummaryId } from 'api/hooks/useGetJobSummaries/types'
import { type FlowInstanceId } from 'api/hooks/useGetRunTasks/types'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

export interface LocationState {
  selectedJobs: JobSummaryId[]
  selectedTasks: FlowInstanceId[]
}

export const useSelectedJobs = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    jobSummaryId,
    flowInstanceId,
    projectId,
    branchId,
    environmentId,
    agentId
  } = useProjectInfo()

  const selectedJobs = useMemo(() => {
    const state = location?.state as LocationState

    if (state?.selectedJobs) {
      return state.selectedJobs
    } else if (jobSummaryId) {
      return [jobSummaryId]
    }

    return []
  }, [location, jobSummaryId])

  const selectedTasks = useMemo<FlowInstanceId[]>(() => {
    const state = location?.state as LocationState

    if (state?.selectedTasks) {
      return state.selectedTasks
    } else if (flowInstanceId) {
      return [flowInstanceId]
    }

    return []
  }, [location, flowInstanceId])

  const navigateToProject = useCallback(
    (newSelectedJobState?: string[]) => {
      navigate(
        {
          pathname: `/project/${projectId}/branch/${branchId}`,
          search: `environmentId=${environmentId}&agentId=${agentId}`
        },
        {
          state: {
            ...location.state,
            selectedJobs: newSelectedJobState ?? selectedJobs,
            selectedTasks:
              newSelectedJobState?.length !== 0 ? selectedTasks : []
          }
        }
      )
    },
    [
      agentId,
      branchId,
      environmentId,
      navigate,
      projectId,
      selectedJobs,
      selectedTasks,
      location.state
    ]
  )

  const navigateToJob = useCallback(
    (
      jobId: JobSummaryId,
      newSelectedJobState?: string[],
      newSelectedTaskState?: FlowInstanceId[]
    ) => {
      navigate(
        {
          pathname: `/project/${projectId}/branch/${branchId}/job/${encodeURIComponent(
            jobId
          )}`,
          search: `environmentId=${environmentId}&agentId=${agentId}`
        },
        {
          state: {
            ...location.state,
            selectedJobs: Array.from(
              new Set([...(newSelectedJobState ?? selectedJobs), jobId])
            ),
            selectedTasks: newSelectedTaskState ?? selectedTasks
          }
        }
      )
    },
    [
      agentId,
      branchId,
      environmentId,
      navigate,
      projectId,
      selectedJobs,
      selectedTasks,
      location.state
    ]
  )

  const navigateToComponent = useCallback(
    (componentId?: string) => {
      const path = componentId ? `/component/${componentId}` : ''

      navigate(
        {
          pathname: `/project/${projectId}/branch/${branchId}/job/${encodeURIComponent(
            jobSummaryId
          )}${path}`,
          search: `environmentId=${environmentId}&agentId=${agentId}`
        },
        {
          state: { ...location.state, selectedJobs, selectedTasks }
        }
      )
    },
    [
      navigate,
      projectId,
      branchId,
      jobSummaryId,
      environmentId,
      agentId,
      selectedJobs,
      selectedTasks,
      location.state
    ]
  )

  const navigateToJobWithSelectedComponent = useCallback(
    (jobId: JobSummaryId, componentId: string) => {
      navigate(
        {
          pathname: `/project/${projectId}/branch/${branchId}/job/${encodeURIComponent(
            jobId
          )}/component/${encodeURIComponent(componentId)}`,
          search: `environmentId=${environmentId}&agentId=${agentId}`
        },
        {
          state: {
            ...location.state,
            selectedJobs: [...selectedJobs, jobId],
            selectedTasks
          }
        }
      )
    },
    [
      navigate,
      projectId,
      branchId,
      environmentId,
      agentId,
      selectedJobs,
      selectedTasks,
      location.state
    ]
  )

  const navigateToTask = useCallback(
    (
      taskId: string,
      newSelectedTaskState?: FlowInstanceId[],
      newSelectedJobState?: string[]
    ) => {
      navigate(
        {
          pathname: `/project/${projectId}/branch/${branchId}/task/${taskId}`,
          search: `environmentId=${environmentId}&agentId=${agentId}`
        },
        {
          state: {
            ...location.state,
            selectedJobs: newSelectedJobState ?? selectedJobs,
            selectedTasks: Array.from(
              new Set([...(newSelectedTaskState ?? selectedTasks), taskId])
            )
          }
        }
      )
    },
    [
      navigate,
      projectId,
      branchId,
      environmentId,
      agentId,
      selectedJobs,
      selectedTasks,
      location
    ]
  )

  const closeJob = useCallback(
    (closedJobId: JobSummaryId) => {
      const currentIndex = selectedJobs.findIndex(
        (selectedId) => selectedId === closedJobId
      )

      if (currentIndex === -1) {
        return
      }

      const remainingJobs = [...selectedJobs]
      remainingJobs.splice(currentIndex, 1)

      const nextJobIndex = Math.min(currentIndex, remainingJobs.length - 1)

      if (remainingJobs.length <= 0) {
        if (selectedTasks.length > 0) {
          navigateToTask(selectedTasks[0], undefined, [])
        } else {
          navigateToProject([])
        }
        return
      }

      if (jobSummaryId !== closedJobId) {
        navigate(location, {
          state: {
            ...location.state,
            selectedJobs: remainingJobs,
            selectedTasks
          }
        })
        return
      }

      navigateToJob(remainingJobs[nextJobIndex], remainingJobs)
    },
    [
      jobSummaryId,
      location,
      navigate,
      navigateToJob,
      navigateToTask,
      navigateToProject,
      selectedJobs,
      selectedTasks
    ]
  )

  const closeTask = useCallback(
    (closedTaskId: FlowInstanceId) => {
      const currentIndex = selectedTasks.findIndex(
        (selectedId) => selectedId === closedTaskId
      )

      if (currentIndex === -1) {
        return
      }

      const remainingTasks = [...selectedTasks]
      remainingTasks.splice(currentIndex, 1)

      const nextTaskIndex = Math.min(currentIndex, remainingTasks.length - 1)

      if (remainingTasks.length <= 0) {
        if (selectedJobs.length > 0) {
          navigateToJob(selectedJobs[0], undefined, [])
        } else {
          navigateToProject([])
        }
        return
      }

      if (flowInstanceId !== closedTaskId) {
        navigate(location, {
          state: {
            ...location.state,
            selectedJobs,
            selectedTasks: remainingTasks
          }
        })
        return
      }

      navigateToTask(remainingTasks[nextTaskIndex], remainingTasks)
    },
    [
      flowInstanceId,
      location,
      navigate,
      navigateToJob,
      navigateToTask,
      navigateToProject,
      selectedJobs,
      selectedTasks
    ]
  )

  const closeAll = useCallback(() => {
    navigateToProject([])
  }, [navigateToProject])

  /**
   * Invalidates any stale selected jobs that no
   * longer exist on the current branch. E.g. after
   * pulling remote changes, some open jobs might have
   * been deleted.
   * @param newJobIds A list of all job ids on the branch
   */
  const invalidateStaleSelectedJobs = useCallback(
    (newJobIds: JobSummaryId[]) => {
      const areNoStaleJobs = selectedJobs.every((selected) =>
        newJobIds.includes(selected)
      )

      if (areNoStaleJobs || selectedJobs.length === 0) {
        return
      }

      const availableJobs = selectedJobs.filter((selected) =>
        newJobIds.includes(selected)
      )

      if (availableJobs.length === 0) {
        closeAll()
        return
      }

      if (availableJobs.includes(jobSummaryId)) {
        navigateToJob(jobSummaryId, availableJobs)
      } else {
        const activeJob = availableJobs[availableJobs.length - 1]
        navigateToJob(activeJob, availableJobs)
      }
    },
    [closeAll, jobSummaryId, navigateToJob, selectedJobs]
  )

  return {
    navigateToProject,
    navigateToJob,
    navigateToJobWithSelectedComponent,
    navigateToComponent,
    navigateToTask,
    closeJob,
    closeTask,
    closeAll,
    selectedJobs,
    selectedTasks,
    invalidateStaleSelectedJobs
  }
}
