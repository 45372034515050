import { type ComponentLayout } from '../../types'
import { createModularConnectorDestinationStep } from '../steps/ModularConnectorDestinationStep'

interface ModularConnectorConfigOptions {
  componentId?: string
  showAdvanced?: boolean
}

export function createModularConnectorConfig(
  dplId: string,
  {
    componentId = `modular-${dplId}`,
    showAdvanced = true
  }: ModularConnectorConfigOptions = {}
): ComponentLayout {
  return {
    componentId,
    wizard: {
      steps: [
        {
          stepId: 'connect',
          displayName: 'Connect',
          previousStepId: null,
          nextStepId: 'configure',
          parameters: [
            {
              dplId: 'connection'
            },
            {
              dplId: 'connectionOptions'
            }
          ]
        },
        {
          stepId: 'configure',
          displayName: 'Configure',
          previousStepId: 'connect',
          nextStepId: 'destination',
          parameters: [
            {
              dplId,
              omitParameters: [
                'connection',
                'connectionOptions',
                'autoDebug',
                'debugLevel'
              ]
            }
          ]
        },
        createModularConnectorDestinationStep('configure', 'advanced'),
        ...(showAdvanced
          ? [
              {
                stepId: 'advanced',
                displayName: 'Advanced Settings',
                previousStepId: null,
                nextStepId: null,
                parameters: [
                  {
                    dplId: 'autoDebug'
                  },
                  {
                    dplId: 'debugLevel'
                  }
                ]
              }
            ]
          : [])
      ]
    }
  }
}
