import {
  createContext,
  useCallback,
  useEffect,
  useRef,
  type FunctionComponent,
  type HTMLProps,
  type PropsWithChildren
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useLDClient, usePendo, useUser } from '@matillion/hub-client'
import classNames from 'classnames'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import {
  useFetchComponentMetadata,
  useGetComponentMetadata
} from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'
import { useGetComponentSummary } from 'api/hooks/useGetComponentSummaries/useGetComponentSummary'
import { type JobSummary } from 'api/hooks/useGetJobSummaries'
import useGetProject from 'api/hooks/useGetProject/useGetProject'
import useRunJob from 'api/hooks/useRunJob/useRunJob'

import {
  ComponentDropDestination,
  type ComponentDropDestinationProps
} from 'components/ComponentDrag/ComponentDropDestination'
import {
  PopOverMenu,
  type PosXy,
  type RenderContentProps,
  type RenderPopOverContent
} from 'components/PopOverMenu'
import { useShortcut } from 'components/ShortcutProvider'

import config from 'config'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'
import { useFlags } from 'hooks/useFlags'
import useGetSelectedComponent from 'hooks/useGetSelectedComponent/useGetSelectedComponent'

import { useDeleteNodes } from 'job-lib/hooks/useDeleteNodes/useDeleteNodes'
import { useGeneratePipelineDocsForSelection } from 'job-lib/hooks/useGeneratePipelineDocsForSelection/useGeneratePipelineDocsForSelection'
import { useMakeComponent } from 'job-lib/hooks/useMakeComponent/useMakeComponent'
import { useMakeNote } from 'job-lib/hooks/useMakeNote/useMakeNote'
import { jobActions } from 'job-lib/store'
import {
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'
import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'
import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

import * as heap from 'utils/heap'
import { isMacOs } from 'utils/isMacOs'

import { useEtlFlow } from '../../hooks/useEtlFlow'
import {
  getSelectedComponentName,
  getSelectedComponentNames
} from '../../util/componentSelection'
import { useCopyComponent } from '../CopyPasteComponent/useCopyComponent'
import { usePasteComponent } from '../CopyPasteComponent/usePasteComponent'
import { useScopedEvent } from '../CopyPasteComponent/useScopedEvent'
import { addTempComponent } from './addTempComponent'
import classes from './Canvas.module.scss'
import { ContextMenu } from './components/ContextMenu'
import { type ComponentNode } from './hooks/useCanvasModel/useCanvasModel'
import { getSelectedNodes } from './hooks/useCanvasModel/utils'

interface CanvasProviderProps extends HTMLProps<HTMLDivElement> {
  job: TransformationJob | OrchestrationJob
  jobSummary: JobSummary
}

export const CanvasContext = createContext<HTMLDivElement | null>(null)

export const CanvasProvider: FunctionComponent<
  PropsWithChildren<CanvasProviderProps>
> = ({ job, jobSummary, children, ...rest }) => {
  const { getDisplayName, getIcon } = useComponentInfo()
  const { t } = useTranslation()
  const reactFlow = useEtlFlow()
  const dispatch = useDispatch()
  const [makeComponent] = useMakeComponent()
  const { getByComponentId } = useGetComponentSummary()

  const { registerShortcut, unRegisterShortcut } = useShortcut()
  const { setValidationEnabled } = useComponentValidationProvider()
  const { deleteNodes } = useDeleteNodes()
  const { rolloutEnableWorkingCopyProvider } = useFlags()

  const project = useGetProject()
  const warehouse = project.data?.warehouse?.toUpperCase()

  const selectedNodes = getSelectedNodes(reactFlow)

  const { mutate: onRunJob } = useRunJob({
    jobId: jobSummary.jobId,
    warehouse
  })

  const { summaryId } = useGetSelectedComponent()
  const { mutate: onRunFromComponent } = useRunJob({
    jobId: jobSummary.jobId,
    warehouse,
    componentName: getSelectedComponentName(summaryId, reactFlow)
  })

  const { data: componentMetadata } = useGetComponentMetadata(summaryId)
  const { mutate: onRunOnlyComponent } = useRunJob({
    jobId: jobSummary.jobId,
    warehouse,
    componentName: getSelectedComponentName(summaryId, reactFlow),
    executeAsSingleComponent:
      componentMetadata?.metadata.executableAsSingleComponent ?? false
  })

  const { copyComponent } = useCopyComponent()
  const { pasteComponent } = usePasteComponent(dispatch)
  const { hasPermission: canRunPipelines } = useProjectPermission(
    'run_pipelines',
    PermissionType.ENVIRONMENT
  )
  const { makeNote } = useMakeNote()
  const { generatePipelineDocs } = useGeneratePipelineDocsForSelection()
  const boundsRef = useRef<HTMLDivElement>(null)
  const { undoManager } = useWorkingCopy()
  const update = useDPLWorkingCopy((state) => state.update)
  const { resetValidation } = useComponentValidationProvider()
  const {
    organisation: { subdomain }
  } = useUser()
  const pendo = usePendo()
  useScopedEvent('copy', boundsRef, () => {
    heap.trackKeyboardShortcut('canvas', 'copy')
    copyComponent()
  })
  useScopedEvent('paste', boundsRef, () => {
    heap.trackKeyboardShortcut('canvas', 'paste')
    pasteComponent()
  })

  const launchDarkly = useLDClient()

  const fetchComponentMetadata = useFetchComponentMetadata()

  const getCanvasPosition = useCallback(
    (pos?: PosXy | null): PosXy => {
      if (!boundsRef.current || !pos) {
        return reactFlow.project({ x: 0, y: 0 })
      }

      const canvasBounds = boundsRef.current.getBoundingClientRect()

      return reactFlow.project({
        x: pos.x - canvasBounds.left,
        y: pos.y - canvasBounds.top
      })
    },
    [reactFlow]
  )

  const handleCommand = useCallback(
    async (id: string, { popOverClientPosition, data }: RenderContentProps) => {
      const pasteComponentWithPosition = () => {
        pasteComponent(getCanvasPosition(popOverClientPosition))
      }

      const undoComponent = () => {
        undoManager?.undo()
        resetValidation()
      }

      const redoComponent = () => {
        undoManager?.redo()
        resetValidation()
      }

      const commands = {
        runJob: () => {
          onRunJob()
        },
        runOnlyComponent: () => {
          onRunOnlyComponent()
        },
        runFromComponent: () => {
          onRunFromComponent()
        },
        validateJob: setValidationEnabled,
        delete: deleteNodes,
        copy: copyComponent,
        paste: pasteComponentWithPosition,
        undo: undoComponent,
        redo: redoComponent,
        addNote: () => {
          makeNote(getCanvasPosition(popOverClientPosition))
        },
        generateDocumentation: async () => {
          generatePipelineDocs(
            getCanvasPosition(popOverClientPosition),
            getSelectedComponentNames(reactFlow)
          )
        },
        editCustomConnector: () => {
          const editConnectorUrl = config.editConnectorUrlTemplate
            .replace('{{org_subdomain}}', subdomain)
            .replace(
              '{{connector_id}}',
              (data as ComponentNode).data.summaryId.replace('custom-', '')
            )
          window.open(editConnectorUrl, '_blank')
        }
      }

      type Commands = typeof commands

      const command = commands[id as keyof Commands]

      if (!command) {
        console.warn('Canvas: context menu command is not yet supported', [id])
        return
      }

      return command()
    },
    [
      onRunJob,
      onRunOnlyComponent,
      onRunFromComponent,
      setValidationEnabled,
      deleteNodes,
      copyComponent,
      pasteComponent,
      getCanvasPosition,
      makeNote,
      undoManager,
      resetValidation,
      generatePipelineDocs,
      reactFlow,
      subdomain
    ]
  )

  const onDropComponent: ComponentDropDestinationProps['onDropComponent'] =
    useCallback(
      async (
        id,
        { initialParameters, relativeDropPoint, dragOrigin, componentName }
      ) => {
        const tempLabel = `${t('statuses.loadingNew')} ${getDisplayName(id)}...`
        const point = reactFlow.project({
          x: relativeDropPoint.x,
          y: relativeDropPoint.y
        })

        addTempComponent(
          id,
          tempLabel,
          point,
          reactFlow,
          getByComponentId(id),
          getIcon
        )

        launchDarkly.track('Designer - Canvas - Add Component')

        if (dragOrigin === 'component-browser') {
          heap.track('etld_add-component_drag', {
            componentType: id,
            componentName
          })
          pendo.track('etld_add-component_drag', {
            componentType: id,
            componentName
          })
        } else if (dragOrigin === 'job-browser') {
          heap.track('etld_add-component_drag-job', {
            componentType: id,
            componentName
          })
          pendo.track('etld_add-component_drag-job', {
            componentType: id,
            componentName
          })
        }

        // istanbul ignore if
        if (rolloutEnableWorkingCopyProvider) {
          const { metadata } = await fetchComponentMetadata(id)
          update((state) => {
            state.addComponent({
              componentId: id,
              componentName,
              componentMetadata: metadata,
              componentDesign: {
                position: point
              },
              initialParameters: Object.entries(initialParameters).reduce<
                Record<string, string>
              >((acc, [key, value]) => {
                acc[key] = value[1].values[1].value
                return acc
              }, {})
            })
          })
        } else {
          dispatch(
            jobActions.addComponent(
              await makeComponent({
                id,
                x: point.x,
                y: point.y,
                componentName,
                initialValues: initialParameters
              })
            )
          )
        }
      },
      [
        t,
        getDisplayName,
        reactFlow,
        getByComponentId,
        getIcon,
        launchDarkly,
        rolloutEnableWorkingCopyProvider,
        pendo,
        fetchComponentMetadata,
        update,
        dispatch,
        makeComponent
      ]
    )

  useEffect(() => {
    /* istanbul ignore next */
    if (canRunPipelines) {
      const isMac = isMacOs()
      /* istanbul ignore next */
      registerShortcut({
        id: 'activeJobTab-RunJob',
        key: 'Enter',
        metaKey: isMac,
        ctrlKey: !isMac,
        callback: () => {
          onRunJob()
        },
        heap: {
          context: 'canvas',
          action: 'runJob'
        }
      })

      return () => {
        unRegisterShortcut('activeJobTab-RunJob')
      }
    }
  }, [
    job,
    pendo,
    registerShortcut,
    unRegisterShortcut,
    onRunJob,
    jobSummary.type,
    canRunPipelines
  ])

  const makePopoverContent = useCallback<RenderPopOverContent>(
    ({ popOverClientPosition, data }) => {
      const componentSummaryId = (data as ComponentNode)?.data?.summaryId
      const selectedComponentNames = getSelectedComponentNames(reactFlow)

      return (
        <ContextMenu
          jobName={jobSummary.name}
          jobType={jobSummary.type}
          onCommand={async (command) =>
            handleCommand(command, { popOverClientPosition, data })
          }
          hasSelectedNodes={Boolean(selectedNodes.length)}
          hasSelectedComponents={Boolean(selectedComponentNames.length)}
          rightClickedComponentSummaryId={componentSummaryId}
        />
      )
    },
    [
      handleCommand,
      jobSummary.name,
      jobSummary.type,
      reactFlow,
      selectedNodes.length
    ]
  )

  return (
    <CanvasContext.Provider value={boundsRef.current}>
      <div
        {...rest}
        ref={boundsRef}
        className={classNames(classes.CanvasProvider, rest.className)}
        // needs to be able to receive focus so we can detect copy and paste events within this element
        tabIndex={-1}
      >
        <PopOverMenu
          positionAtMouse
          content={makePopoverContent}
          boundaryElement={boundsRef.current ?? undefined}
        >
          {({ onContextMenu }) => (
            <ComponentDropDestination
              onContextMenu={onContextMenu}
              onDropComponent={onDropComponent}
              data-testid="canvas-container"
              className={classes.Canvas}
            >
              {children}
            </ComponentDropDestination>
          )}
        </PopOverMenu>
      </div>
    </CanvasContext.Provider>
  )
}
