import { type File, type FileType } from '@matillion/git-component-library'

import { type RunTaskStatus } from 'api/hooks/useGetRunTasks/types'

export enum TaskType {
  FlowSummary = 'FlowSummary'
}

export type EtlDocumentTypes = FileType | TaskType

export interface EtlDocument {
  key: string
  id: string
  type: EtlDocumentTypes
  title: string
  document: File | RunTaskStatus
  isActive: boolean
  closeDocument: () => void
  openDocument: () => void
}
