import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip, Typography } from '@matillion/component-library'
import { FileIcon, type Files } from '@matillion/git-component-library'
import { useVirtualizer } from '@tanstack/react-virtual'
import classnames from 'classnames'
import { Command } from 'cmdk'

import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { usePipelines } from 'hooks/usePipelines/usePipelines'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import * as heap from 'utils/heap'

import classes from './CommandPalette.module.scss'

interface FileExplorerProps {
  searchValue: string
  onFileSelection: () => void
}

const getFilteredFiles = (files: Files, searchValue: string) => {
  /**
   * Sort files alphabetically on display name
   */
  const sortedFiles = Object.values(files).sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  )

  if (searchValue === '') {
    return sortedFiles
  }

  return sortedFiles.filter((file) =>
    file.displayName.toLowerCase().includes(searchValue.toLowerCase())
  )
}

function track(files: string[], file: string) {
  if (files.includes(file)) {
    heap.track('etld_command-palette_switch-item')
  } else {
    heap.track('etld_command-palette_open-item')
  }
}

export const FileExplorer = ({
  searchValue,
  onFileSelection
}: FileExplorerProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'commandPalette' })
  const { files, isLoading, isError } = usePipelines()
  const { selectedJobs: selectedFiles } = useSelectedJobs()
  const { navigateToJob: goToFile } = useSelectedJobs()

  const listRef = useRef<HTMLDivElement>(null)

  const filteredFiles = useMemo(
    () => getFilteredFiles(files, searchValue),
    [files, searchValue]
  )

  const fileItemVirtualiser = useVirtualizer({
    count: filteredFiles.length,
    /*
     * The number of items to render above and below the visible area of the list.
     * This can help to reduce the number of items that need to be rendered when scrolling.
     */
    overscan: 10,
    getScrollElement: () => listRef.current,
    /*
     * The estimated size/height of each item.
     */
    estimateSize: () => 32
  })

  return (
    <Command.List ref={listRef} tabIndex={0}>
      {!isLoading && !isError && (
        <Command.Empty
          className={classes.NoResultsFound}
          data-testid="file-explorer-no-results"
        >
          <Typography format="bcm">{t('search.noResultsFoundText')}</Typography>
        </Command.Empty>
      )}

      {isLoading && <Loading text={t('fileExplorer.loading')} />}

      {isError && (
        <EmptyPanel data-testid="file-explorer-error">
          {t('fileExplorer.error')}
        </EmptyPanel>
      )}

      <div>
        <Command.Group
          style={{
            height: `${fileItemVirtualiser.getTotalSize()}px`
          }}
        >
          {fileItemVirtualiser.getVirtualItems().map((item) => {
            const file = filteredFiles[item.index]

            return (
              <Command.Item
                data-testid="file-explorer-item"
                data-tracker-id="file-explorer-item"
                key={file.name}
                onSelect={() => {
                  goToFile(file.name)
                  onFileSelection()
                  track(selectedFiles, file.name)
                }}
                data-index={item.index}
                ref={fileItemVirtualiser.measureElement}
                style={{
                  // dynamically position the element relative to its starting position
                  // start position is calculated based on the index of the item and the estimated size of each item
                  transform: `translateY(${item.start}px)`
                }}
              >
                <div className={classes.FileItem}>
                  <FileIcon type={file.type} />
                  <Tooltip
                    onlyShowOnOverflow
                    content={`${file.displayName}${file.extension}`}
                  >
                    <span
                      className={classnames(
                        classes.FileItem__FileName,
                        'u-truncate-text'
                      )}
                    >
                      {file.displayName}
                    </span>
                  </Tooltip>

                  <Tooltip onlyShowOnOverflow content={file.folder}>
                    <span
                      className={classnames(
                        classes.FileItem__FolderPath,
                        'u-truncate-text'
                      )}
                    >
                      {file.folder}
                    </span>
                  </Tooltip>
                </div>
              </Command.Item>
            )
          })}
        </Command.Group>
      </div>
    </Command.List>
  )
}
