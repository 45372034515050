import { useCallback, useMemo, type MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { FileType, type File } from '@matillion/git-component-library'
import classnames from 'classnames'

import { type RunTaskStatus } from 'api/hooks/useGetRunTasks/types'

import { InteractiveTab, InteractiveTabs } from 'components/InteractiveTabs'

import { usePipelines } from 'hooks/usePipelines/usePipelines'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useSelectedJobs } from 'hooks/useSelectedJobs'
import { useSelectedTasksActivity } from 'hooks/useSelectedTasksActivity/useSelectedTasksActivity'

import { EtlDocumentTabIcon } from './components/EtlDocumentTabIcon'
import classes from './FileTabs.module.scss'
import { TaskType, type EtlDocument } from './types'

const formatSelectedDocuments = <DocType,>(
  selectedValues: string[],
  mapper: (e: string) => DocType | undefined,
  formatter: (e: DocType) => EtlDocument
): EtlDocument[] => {
  return selectedValues.reduce<EtlDocument[]>((acc, currentValue) => {
    const documentType = mapper(currentValue)

    if (!documentType) {
      return acc
    }

    const document: EtlDocument = formatter(documentType)
    acc.push(document)

    return acc
  }, [])
}

export const FileTabs = () => {
  const { files, isError } = usePipelines()
  const { t } = useTranslation()

  const { jobSummaryId: activeJobId, flowInstanceId: activeTaskId } =
    useProjectInfo()
  const {
    selectedJobs: selectedFiles,
    selectedTasks,
    navigateToJob,
    navigateToTask,
    closeJob,
    closeTask,
    closeAll
  } = useSelectedJobs()

  const tasks = useSelectedTasksActivity(selectedTasks)

  const selectedDocuments = useMemo(() => {
    const formattedFiles = formatSelectedDocuments<File>(
      selectedFiles,
      (selectedId) =>
        Object.values<File>(files).find((file) => file.name === selectedId),
      (file) => ({
        key: `${file.type}-${file.name}`,
        id: file.name,
        document: file,
        title: file.fileName,
        type: file.type ?? FileType.UNKNOWN,
        isActive: file.name === activeJobId,
        closeDocument: () => {
          closeJob(file.name)
        },
        openDocument: () => {
          navigateToJob(file.name)
        }
      })
    )

    const formattedTasks = formatSelectedDocuments<RunTaskStatus>(
      selectedTasks,
      (id) => tasks.find((task) => task.id.toString() === id),
      (e) => ({
        key: `${TaskType.FlowSummary}-${e.id}`,
        id: e.id.toString(),
        document: e,
        title: `${t('taskHistory.tabNamePrefix')} ${e.jobName}`,
        type: TaskType.FlowSummary,
        isActive: e.id.toString() === activeTaskId,
        closeDocument: () => {
          closeTask(e.id.toString())
        },
        openDocument: () => {
          navigateToTask(e.id.toString())
        }
      })
    )

    return [...formattedFiles, ...formattedTasks]
  }, [
    files,
    tasks,
    selectedFiles,
    selectedTasks,
    activeJobId,
    activeTaskId,
    closeJob,
    closeTask,
    navigateToJob,
    navigateToTask,
    t
  ])

  const activeTabIndex = useMemo(
    () => selectedDocuments.findIndex((document) => document.isActive),
    [selectedDocuments]
  )

  const onTabChange = useCallback(
    (tab: { value: number }) => {
      const document = selectedDocuments[tab.value]
      document.openDocument()
    },
    [selectedDocuments]
  )

  const onTabClose = useCallback((event: MouseEvent, document: EtlDocument) => {
    event.preventDefault()
    event.stopPropagation()
    document.closeDocument()
  }, [])

  const onCloseAllTabs = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      closeAll()
    },
    [closeAll]
  )

  if (isError) {
    return null
  }

  if (selectedDocuments.length <= 0) {
    return null
  }

  return (
    <InteractiveTabs
      className={classes.FileTabs__Tabs}
      contentClassName={classes.FileTabs__TabContent}
      listClassName={classes.FileTabs__TabList}
      activeIndex={activeTabIndex}
      onChange={onTabChange}
      onCloseAll={onCloseAllTabs}
    >
      {selectedDocuments.map((doc: EtlDocument, index) => (
        <InteractiveTab
          key={doc.key}
          data-testid={`tab-${doc.key}`}
          className={classnames(classes.FileTabs__Tab, {
            [classes['FileTabs__Tab--Orchestration']]:
              doc.type === FileType.ORCHESTRATION_PIPELINE,
            [classes['FileTabs__Tab--Transformation']]:
              doc.type === FileType.TRANSFORMATION_PIPELINE,
            [classes['FileTabs__Tab--ActiveTab']]: index === activeTabIndex
          })}
          internalKey={doc.key}
          title={doc.title}
          tooltip={doc.title}
          iconBefore={<EtlDocumentTabIcon documentType={doc.type} />}
          onTabClose={(event: MouseEvent) => {
            onTabClose(event, doc)
          }}
        />
      ))}
    </InteractiveTabs>
  )
}
